import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from '@dfds-ui/theme'
import { ExternalLink as ExternalLinkIcon } from '@dfds-ui/icons/system'

const ExternalLinkBase = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bolder;
`
const LinkText = styled.span`
  color: ${(props) => props.backgroundcolor && theme.colors.text.light.primary};
  text-align: right;
`

const ExternalLink = (props) => {
  let { url, linkText, backgroundcolor, ...rest } = props

  const urlParts = url.split('/')

  const dfdsLink =
    urlParts.length > 3 &&
    (urlParts[2] === 'dfds.com' || urlParts[2] === 'www.dfds.com')

  // When origin is dfds.com or www.dfds.com, origin is removed to make sure the link work in all environments.
  // One usage can be seen on https://www.dfds.com/en/passenger-ferries/my-booking
  if (dfdsLink) url = `/${urlParts.slice(3).join('/')}`

  return (
    <ExternalLinkBase
      {...rest}
      href={url}
      target="_blank"
      {...(!dfdsLink && { rel: 'noopener noreferrer' })} // only add this when external links
    >
      <LinkText backgroundcolor={backgroundcolor}>{linkText}</LinkText>
      <ExternalLinkIcon
        width={'24px'}
        height={'20px'}
        color={theme.colors.text.secondary.primary}
        css={css`
          margin-left: 10px;
        `}
      />
    </ExternalLinkBase>
  )
}

export const externalLinkFragmernt = graphql`
  fragment Url on contentful_Url {
    __typename
    sys {
      id
    }
    url
    linkText
    description
  }
`

export default ExternalLink
